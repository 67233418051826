* {
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0;
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: Chronicle Display Semibold;
  font-style: normal;
  font-weight: normal;
  src: local(Chronicle Display Semibold), url("Chronicle Display Semibold.191c6ce4.woff") format("woff");
  font-display: optional;
}

@font-face {
  font-family: Chronicle Display Black;
  font-style: normal;
  font-weight: normal;
  src: local(Chronicle Display Black), url("Chronicle Display Black.b7e9d62c.woff") format("woff");
  font-display: optional;
}

@font-face {
  font-family: Gilroy-Bold;
  font-style: normal;
  font-weight: normal;
  src: local(Gilroy-Bold ☞), url("Gilroy-Bold.1129270d.woff") format("woff");
  font-display: optional;
}

img {
  width: 100%;
  height: 100%;
}

svg {
  vertical-align: middle;
}

:root {
  --clr-1: #fff;
  --clr-2: #fbd784;
  --clr-3: #0b1d26;
  --font-family-1: "Chronicle Display Semibold", sans-serif;
  --font-family-2: "Chronicle Display Black", sans-serif;
  --font-family-3: "Gilroy-Bold", sans-serif;
  --font-size-1: .9rem;
  --font-size-2: 2rem;
  --font-size-3: 1.1rem;
  --font-size-4: 3.375rem;
  --font-size-5: 4.5rem;
  --font-size-6: 15rem;
  --font-size-7: 4rem;
  --font-size-8: 1.5rem;
  --line-height-1: 2rem;
  --line-height-2: 1.5rem;
  --line-height-3: 2.25rem;
  --line-height-4: 4.35rem;
  --line-height-5: 5.6rem;
  --line-height-6: 15rem;
  --line-height-7: 1.375rem;
}

body {
  color: var(--clr-1);
  font-family: var(--font-family-3);
  background-color: var(--clr-3);
  line-height: var(--line-height-1);
  content-visibility: auto;
}

.section {
  width: min(90%, 90rem);
  margin-inline: auto;
}

.yellow-line {
  color: var(--clr-2);
  font-size: var(--font-size-1);
  line-height: var(--line-height-2);
  letter-spacing: .375rem;
  text-transform: uppercase;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.yellow-line:before {
  content: "";
  width: 4.5rem;
  border: solid 1px var(--clr-2);
}

.header {
  --w-min: 40rem;
  --max-width: 120rem;
  --ar-figure: 1920 / 2800;
  --h-figure: calc( max(var(--w-min), min(var(--max-width), 100vw) ) / (var(--ar-figure)) );
  max-width: 120rem;
  height: var(--h-figure);
  margin-inline: auto;
  margin-bottom: 25rem;
  position: relative;
}

.header:after {
  content: "";
  width: 100%;
  height: 50rem;
  z-index: 4;
  background: linear-gradient(#0b1d2600 0%, #0b1d26 61.38%);
  position: absolute;
  bottom: -20rem;
  left: 0;
}

.header-background {
  width: 100%;
  height: 100%;
  position: absolute;
}

.header-background:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(20deg, #0b1d2600 31.06%, #0b1d26 108.93%);
  position: absolute;
}

.header-image {
  width: 100%;
  height: calc( max(var(--w-min), min(var(--max-width), 100vw) ) / (var(--aspect-ratio)) );
  display: block;
  position: absolute;
}

.header-image img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 30% 0;
  object-position: 30% 0;
}

@media (min-width: 720px) {
  .header-image img {
    -o-object-position: left;
    object-position: left;
  }
}

.header-image-1 {
  --aspect-ratio: 360 / 284;
}

.header-image-2 {
  --aspect-ratio: 360 / 267;
  top: 42%;
}

.header-image-3 {
  --aspect-ratio: 360 / 174;
  --img-top: .6;
  top: calc(var(--img-top) * var(--h-figure));
  z-index: 3;
  position: sticky;
}

.header-image-3:after {
  content: "";
  width: 100%;
  height: 10rem;
  background: var(--clr-3);
  position: absolute;
  top: 95%;
  left: 0;
}

@media (min-width: 720px) {
  .header {
    --ar-figure: 1920 / 2620;
  }

  .header-image-2 {
    top: 38%;
  }

  .header-image-3 {
    --img-top: .5;
  }
}

@media (min-width: 1080px) {
  .header {
    --ar-figure: 1920 / 2320;
  }

  .header-image-2 {
    top: 30%;
  }

  .header-image-3 {
    --img-top: .4;
  }
}

@media (min-width: 1440px) {
  .header {
    --ar-figure: 1920 / 2120;
  }

  .header-image-2 {
    top: 23%;
  }

  .header-image-3 {
    --img-top: .36;
  }

  .header-image-3:after {
    height: 12rem;
  }
}

@media (min-width: 1920px) {
  .header {
    --ar-figure: 1920 / 1990;
  }

  .header-image-2 {
    top: 18%;
  }

  .header-image-3 {
    --img-top: .3;
  }

  .header-image-3:after {
    height: 18rem;
  }
}

.header-navbar > * {
  cursor: pointer;
  fill: #fff;
  color: #fff;
  z-index: 5;
  border: none;
  text-decoration: none;
  transition-property: color, fill, transform;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
}

.header-navbar > :hover {
  color: var(--clr-2);
  fill: var(--clr-2);
  transform: translateY(-.5rem);
}

.nav-logo {
  font-family: var(--font-family-2);
  font-size: var(--font-size-2);
  line-height: var(--line-height-3);
  margin-right: auto;
}

.header-navbar {
  align-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2.5rem 0;
  display: flex;
}

.nav-item {
  display: none;
}

.nav-user {
  font-size: var(--font-size-3);
  margin-left: auto;
}

@media (min-width: 720px) {
  .nav-item {
    display: block;
  }
}

.header-content {
  top: calc(.12 * var(--h-figure));
  z-index: 2;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  position: sticky;
}

.content-header {
  width: min(18ch, 90vw);
  font-family: var(--font-family-1);
  font-size: var(--font-size-4);
  line-height: var(--line-height-4);
}

.content-scroll {
  font-family: var(--font-family-1);
  line-height: var(--line-height-7);
}

.content-scroll picture {
  margin-left: 1ch;
  display: inline-block;
}

.content-scroll picture img {
  vertical-align: middle;
}

@media (min-width: 1080px) {
  .header-navbar {
    padding: 3rem 0;
  }

  .content-header {
    font-size: var(--font-size-5);
    line-height: var(--line-height-5);
  }
}

.main-content {
  counter-reset: section;
}

.card-image {
  height: 20rem;
  width: 80%;
  margin-inline: auto;
  display: block;
}

.main-content > .main-content-card:nth-child(2) .card-image img {
  -o-object-position: left;
  object-position: left;
}

.card-image > img {
  -o-object-fit: cover;
  object-fit: cover;
}

.card-item {
  margin: 5rem auto 12.5rem;
  position: relative;
}

.card-item:before {
  counter-increment: section;
  content: "0" counter(section);
  line-height: var(--line-height-6);
  font-size: var(--font-size-6);
  opacity: .1;
  position: absolute;
  top: -5rem;
  left: -8.5rem;
}

.card-header {
  font-family: var(--font-family-1);
  font-size: var(--font-size-7);
  line-height: var(--line-height-4);
  max-width: 15ch;
  margin-top: 2rem;
  margin-bottom: 1.68rem;
}

.card-paragraph {
  font-size: var(--font-size-3);
  line-height: var(--line-height-1);
  max-width: 54ch;
  font-weight: 700;
}

.card-yellow-arrow {
  color: var(--clr-2);
}

.card-yellow-arrow svg {
  fill: var(--clr-2);
  margin-left: 2ch;
  transform: rotate(270deg);
}

@media (min-width: 360px) {
  .card-image {
    width: min(37.5rem, 95%);
    height: 35rem;
  }
}

@media (min-width: 1080px) {
  .main-content-card {
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 2rem;
    margin: 5rem auto 15rem;
    display: flex;
  }

  .main-content > .main-content-card:nth-child(2) {
    flex-direction: row;
  }

  .card-image {
    width: 40%;
    height: 45rem;
    margin: 0;
  }

  .card-item {
    align-self: center;
    margin: 0;
  }
}

.footer {
  margin-bottom: 3rem;
}

.footer-list {
  margin-bottom: 4rem;
  padding-left: 0;
  list-style-type: none;
}

.list-header {
  font-size: var(--font-size-2);
  font-family: var(--font-family-1);
  margin-bottom: 1rem;
}

.list-par {
  max-width: 25ch;
  margin-bottom: 3rem;
}

.list-cc {
  max-width: 32ch;
  opacity: .6;
  color: var(--clr-1);
  margin-top: auto;
}

.list-yellow {
  color: var(--clr-2);
  font-size: var(--font-size-8);
  margin-bottom: 1rem;
}

@media (min-width: 1080px) {
  .footer {
    gap: 3rem;
    display: flex;
  }

  .footer-list {
    margin-bottom: 0;
  }

  .footer > :nth-child(2) {
    margin-left: auto;
  }
}

/*# sourceMappingURL=index.0ea33641.css.map */
